@import '@agentguru/foundations/dist/scss/main.scss';

.container {
  @include background-color(neutral, white);
  @include padding-left(md);
  @include padding-right(lg);
  @include border-bottom(neutral, gray-3);

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.5rem;
  width: 100%;

  > i {
    @include icon-size(lg);
    @include color(neutral, gray-4);
  }

  > div {
    display: flex;
    align-items: center;

    > i {
      @include color(neutral, gray-5);
      @include margin-right(base);
    }

    > span {
      @include typography(roboto, sm, medium);
      @include color(primary, blue-9);
    }
  }
}
