@import '@agentguru/foundations/dist/scss/main.scss';

.userProfileContainer {
  @include padding-left(md);
  @include padding-right(lg);
  @include background-color(neutral, white);

  display: flex;
  align-items: center;
  height: 5.5rem;
  width: 100%;
  justify-content: space-between;
  text-align: left;

  > div > div {
    &:first-child {
      @include margin-right(base);
    }
  }

  > i {
    @include color(primary, blue-9);
  }

  &:active {
    @include background-color(neutral, gray-2);
  }
}

.container {
  display: flex;
  align-items: center;

  > div {
    &:first-of-type {
      @include margin-right(md);
    }

    &:last-of-type {
      > h3 {
        @include color(primary, blue-9);
        @include typography(roboto, sm, medium);
        @include margin-bottom(xs);
      }

      > span {
        @include color(neutral, gray-5);
        @include typography(roboto, xs, regular);
      }
    }
  }
}

.agencyContainer {
  @include padding-horizontal(md);
  @include padding-bottom(md);
  @include border-bottom(neutral, gray-3);
}
